import { PipeTransform } from '@angular/core';
var LeaveOnlyImportantLinePipe = /** @class */ (function () {
    function LeaveOnlyImportantLinePipe() {
    }
    LeaveOnlyImportantLinePipe.prototype.transform = function (value, mail, enable) {
        if (enable === void 0) { enable = true; }
        if (enable && mail.important_line_judge) {
            var importantLineJudge = void 0;
            try {
                importantLineJudge = JSON.parse(mail.important_line_judge);
            }
            catch (e) {
                console.error('Failed to parse important_line_judge:', e);
                return value; // パースに失敗した場合は元の値を返す
            }
            var importantLineFlag = 1;
            var _loop_1 = function (lineNumberString) {
                if (importantLineJudge.hasOwnProperty(lineNumberString)) {
                    var lineNumber_1 = Number(lineNumberString) + 1;
                    if (importantLineJudge[lineNumberString] < importantLineFlag) {
                        value = value.replace(/.*\r?\n/g, function (match) {
                            if (--lineNumber_1 === 0) {
                                return 'lineDeleteFlag\n';
                            }
                            else {
                                return match;
                            }
                        });
                    }
                }
            };
            // 重要行以外に削除フラグを立てる処理。一度フラグを立てないと行がずれるためとりあえず。よくわからないけど、booleanではなく、6とか入っていた。
            // objectに対するfor inを逆から処理できるやり方があったらそっちの方がいいかも。
            for (var lineNumberString in importantLineJudge) {
                _loop_1(lineNumberString);
            }
            // 重要行以外のフラグ一気に全削除。
            value = value.replace(/lineDeleteFlag\n/g, '');
        }
        return value;
    };
    return LeaveOnlyImportantLinePipe;
}());
export { LeaveOnlyImportantLinePipe };

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AccountService } from 'src/app/shared/service/account.service';
import { SessionService } from 'src/app/shared/service/session.service';
import { Account } from 'src/app/shared/model/account.model';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { SameAccountService } from 'src/app/shared/service/same-account.service';
import { NewReceivedMailService } from 'src/app/shared/service/new-received-mail.service';
import { AdminMessageService } from 'src/app/shared/service/admin-message.service';
import { ClickEventService } from 'src/app/shared/service/click-event.service';
import { GoogleAnalysticsService } from 'src/app/shared/service/google-analystics.service';
import { V2_FRONT_URL } from '../../../environments/environment';
import { MailRegisterService } from 'src/app/shared/service/mail-register.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  alive = true;
  account: Account;
  isAdmin: boolean;

  isProjects = true;
  isWorkers = false;
  isReply = false;
  isAuto = false;
  isOffers = false;
  isMailRegister = false;
  isReport = false;
  isRegistration = false;
  isSetting = false;

  projectNewReceivedMailNumber: number;
  workerNewReceivedMailNumber: number;
  offerNewReceivedNumber: number;
  newReceivedSubscription: Subscription;
  routerEventsSubscription: Subscription;

  v2FrontUrl = V2_FRONT_URL;

  constructor(
    private router: Router,
    public accountService: AccountService,
    private sessionService: SessionService,
    private toastrService: ToastrService,
    private newReceivedMailService: NewReceivedMailService,
    private clickEventService: ClickEventService,
    private googleAnalysticsService: GoogleAnalysticsService,
    private adminMessageService: AdminMessageService,
    private mailRegisterService: MailRegisterService
  ) {}

  ngOnInit() {
    // routesイベントの監視
    this.routerEventsListener();
    this.newReceivedMailListener();

    this.account = this.accountService.getAccount();
    this.isAdmin = this.accountService.isAdminUser();
  }

  ngOnDestroy() {
    this.alive = false;
    if (this.newReceivedSubscription) {
      this.newReceivedSubscription.unsubscribe();
    }
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }

  // routesイベントの監視
  private routerEventsListener(): void {
    this.routerEventsSubscription = this.router.events.subscribe((nav: any) => {
      if (nav instanceof NavigationEnd) {
        this.googleAnalysticsService.pageView(nav.url);
        this.isProjects = false;
        this.isWorkers = false;
        this.isReply = false;
        this.isAuto = false;
        this.isOffers = false;
        this.isMailRegister = false;
        this.isReport = false;
        this.isSetting = false;
        this.isRegistration = false;
        if (nav.url.match(/matching\/projects/)) {
          this.isProjects = true;
        } else if (nav.url.match(/matching\/workers/)) {
          this.isWorkers = true;
        } else if (nav.url.match(/replies/)) {
          this.isReply = true;
        } else if (nav.url.match(/matching\/auto/)) {
          this.isAuto = true;
        } else if (nav.url.match(/offer/)) {
          this.isOffers = true;
        } else if (nav.url.match(/mail-register/)) {
          this.isMailRegister = true;
        } else if (nav.url.match(/report/)) {
          this.isReport = true;
        } else if (nav.url.match(/account\/setting/)) {
          this.isSetting = true;
        } else if (nav.url.match(/registration/)) {
          // account(権限なし=>権限あり) 切り替えの時this.isAdminは更新されない為,this.isAdminを使わない
          // 何故かtemplateでは反映される???
          // this.isAdmin = this.accountService.isAdminUser();
          if (this.accountService.isAdminUser()) {
            this.isRegistration = true;
          } else {
            this.toastrService.warning('権限がない');
            this.router.navigate(['/']);
          }
        }
      }
    });
  }

  // 新着メール確認イベントの監視
  private newReceivedMailListener(): void {
    // 新着メール確認イベント受信時の処理
    this.newReceivedSubscription = this.newReceivedMailService.newReceivedState.subscribe(
      res => {
        this.projectNewReceivedMailNumber = res.new_projects_count;
        this.workerNewReceivedMailNumber = res.new_workers_count;
        this.offerNewReceivedNumber = res.new_offers_count;
      }
    );
    // 新着メール確認
    this.adminMessageService.updateMessages(
      this.newReceivedMailService.newMailsIds
    );
    interval(30000)
      .pipe(takeWhile(() => this.alive))
      .subscribe(() => {
        // 新着メール
        this.adminMessageService.updateMessages(
          this.newReceivedMailService.newMailsIds
        );
      });
  }

  logout() {
    if (localStorage.account) {
      this.sessionService.signOut().subscribe(res => {
        this.toastrService.success('ログアウトしました');
        // ログイン画面遷移
        this.router.navigate(['/login']);
      });
    } else {
      this.toastrService.success('ログアウトしました');
      // ログイン画面遷移
      this.router.navigate(['/login']);
    }
  }

  private scrollUp() {
    window.scrollTo(0, 0);
  }

  projectClick() {
    if (this.isProjects) {
      this.clickEventService.projectTabSubject.next('');
    }
    this.scrollUp();
  }

  workerClick() {
    if (this.isWorkers) {
      this.clickEventService.workerTabSubject.next();
    }
    this.scrollUp();
  }

  autoClick() {
    if (this.isAuto) {
      this.clickEventService.autoTabSubject.next();
    }
    this.scrollUp();
  }

  offerClick() {
    if (this.isOffers) {
      this.clickEventService.offerTabSubject.next();
    }
    this.scrollUp();
  }

  windowClose(): void {
    window.close();
  }

  // 詳細ページかどうか
  isDetailPage(): boolean {
    const url = this.router.url;
    return !!url.match(/matching\/.*\/[0-9]*/);
  }
  logoPath(): string {
    return this.accountService.themeMode
      ? 'assets/img/logo.png'
      : 'assets/img/logo_dark.svg';
  }

  onCloudMatchClick(event: Event, path: string) {
    event.preventDefault();
    this.mailRegisterService.checkOpenToCloud().subscribe(response => {
      if (!response.body.has_open_to_cloud) {
        this.toastrService.warning('クラウドマッチをご利用になるには、１件以上の人材をクラウドマッチに提示する必要があります');
      } else {
        window.location.href = this.v2FrontUrl + path;
      }
    });
  }

  superReloadAndNavigate(url: string) {
    window.location.href = url;
  }
}
